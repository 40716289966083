$bgColor: #FFFFFF;
$tableBorderBottomColor: #E0E3E8;
$oddBgColor: #F8F9FA;
$evenBgColor: #FFFFFF;
$tableTextColor: #000;

.aui {
  
  .table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 20px;
    border-spacing: 0;
  }

  table thead th,
  table thead td,
  table tfoot th,
  table tfoot td {
    text-align: left;
    border: none;
    background: $bgColor;
  }
  
  .table > thead, 
  .table > tbody{
    border-bottom: 2px solid $tableBorderBottomColor;
  }
  
  table thead th{
    border-top: 0px !important;
    white-space: nowrap;
    font-size: 1rem;
  }
  
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
  }
  
  .table tbody tr.table-font {
    font-size: 0.8rem;
    font-weight: 500;
    color: $tableTextColor;
  }
  
  
  .table.table-striped tbody>tr:nth-child(odd)>td, 
  .table.table-striped tbody>tr:nth-child(odd)>th{
    background-color: $oddBgColor;
    vertical-align: middle;
    box-shadow: none;
  }
  
  .table.table-striped tbody>tr:nth-child(even)>td, 
  .table.table-striped tbody>tr:nth-child(even)>th{
    background-color: $evenBgColor;
    vertical-align: middle;
    box-shadow: none;
  }
  th {
    font-size:14px;
  }

  th .sortcolumn.sort-by { 
    padding-right: 18px;
    position: relative;
  }
  .sortcolumn.sort-by:before,
  .sortcolumn.sort-by:after {
    border: 4px solid transparent;
    content: "";
    display: block;
    height: 0;
    right: 5px;
    top: 50%;
    position: absolute;
    width: 0;
  }
  .sortcolumn.sort-by:before {
    border-bottom-color: #a5a5a5;
    margin-top: -7px;
  }
  .sortcolumn.sort-by:after {
    border-top-color: #a5a5a5;
    margin-top: 4px;
  }
  .sortcolumn.sort-by.asc:before {
    border-bottom-color: #000;
  }
  .sortcolumn.sort-by.desc:after{
      border-top-color: #000;
  }

  .label-action{
    width:100%;
    display: block;
  }
  
  .filter-table-text{
    display: block;
    width: 100%;
    padding: 0.2rem;
    font-size: 16px;
    font-weight: 400;
    line-height: 0.5;
    color: #495057;
    background-color: transparent;
    background-clip: padding-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid rgba(73, 80, 87, 0.25);
    border-radius: 0;
  }


  ul.pagination-custom{
    padding:0px !important;
  }
  
}
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  /*  Gestione mobile */
  
  @media screen and (max-width: 74.9375em){
    .aui {
      .table {
        width: max-content;
      }
    }
  }
  
  @media only screen and (max-width: 800px) {
    /* Force table to not be like tables anymore */
    #no-more-tables table,
    #no-more-tables thead,
    #no-more-tables tbody,
    #no-more-tables tfoot,
    #no-more-tables th,
    #no-more-tables td,
    #no-more-tables tr {
    display: block;
    }
     
    /* Hide table headers (but not display: none;, for accessibility) */
    #no-more-tables thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
    }
     
    #no-more-tables tr {
      border: 1px solid #ccc; 
    }
      
    #no-more-tables td {
    /* Behave like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
    white-space: normal;
    text-align: left;
    min-height: 41px;
    }
    #no-more-tables tfoot td {
      /* Behave like a "row" */
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 0px;
      white-space: normal;
      text-align:left;
    }
     
    #no-more-tables td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align:left;
    font-weight: bold;
    }
     
    /*
    Label the data
    */
    #no-more-tables td:before { 
      content: attr(data-title); 
    }
  
    td {
      word-break: break-all;
    }
  
  
    }