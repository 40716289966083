.aui{
    .btn {
        display: inline-block;
        padding: 4px 12px;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        color: #333333;
        text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
        background-color: #f1f1f1;
        background-image: linear-gradient(to bottom, #f6f6f6, #eaeaea);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFF6F6F6", endColorstr="#FFEAEAEA", GradientType=0);
        border-color: #eaeaea #eaeaea #c4c4c4;
        border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
        border: 1px solid #cfcfcf;
        border-bottom-color: #b5b5b5;
        border-radius: 2px;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    }

    .btn:hover, .btn:focus {
        color: #333333;
        text-decoration: none;
        background-position: 0 -15px;
        transition: background-position 0.1s linear;
    }


    a.btn.btn-circle {
        width: 30px;
        height: auto;
        padding: 6px 0px;
        border-radius: 15px;
        text-align: center;
        font-size: 12px;
        line-height: 1.42857;
        background: transparent;
    }
    
    a.btn.btn-circle:hover{
        color:#fff;
        background-color:#00965e;
    }
}


