
.aui{
  ul.pagination *, ul.pagination::before, ul.pagination::after{
    box-sizing:border-box;
  }

  .pagination {
    margin: 20px 0;
  }
  
  .pagination-position {
      margin: auto;
      float: left;
  }

  .pagination-position ul.pagination {
    margin: 20px 0;
    list-style: none !important;
  }
  
  .pagination li.page-item a{
    font-family: 'Roboto', sans-serif;
      font-size: 15px;
      font-weight: 500;
      color: #495057;
      cursor: pointer;
      border-radius: 100% !important;
      padding: 0.4em 0.7em;
      border: none;
      text-decoration: none;
      width: 30px;
      height: 30px;
      box-shadow: none !important;
      margin-left: 3px;
      margin-right: 3px;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      padding: 0;
  }
  
  .pagination li.page-item.disabled a:not(.next) a:not(.previous) {
    border: 1px solid #cacaca;
    padding: 0.4em 0.7em;
    border-radius: 100%;
    font-size: 15px;
  }
  
  .pagination li.page-item a:not(.disabled):not(.next):not(.previous):hover,
  .pagination li.page-item.current a{
    background: #00975E;
    color: #fefefe;
  }
  
  .pagination li.page-item.active a{
      cursor: default;
      background: #00975E;
      color: #fefefe;
   }
  
  .pagination ul li a {
    transition: background-color 0.15s ease-in, color 0.15s ease-in;
    margin-left: 2px;
    margin-right: 2px;
  }  
  

  
  li.paginate_button a {
      font-family: 'Roboto', sans-serif;
      font-size: 15px;
      font-weight: 500;
      color: #495057;
      cursor: pointer;
      border-radius: 100% !important;
      padding: 0.4em 0.7em;
      border: none;
      text-decoration: none;
      width: 30px;
      height: 30px;
      box-shadow: none !important;
  }
  
  li.paginate_button.disabled a:not(.next) a:not(.previous) {
      border: 1px solid #cacaca;
      padding: 0.4em 0.7em;
      border-radius: 100%;
      font-size: 15px;
  }
  
  .paging_simple_numbers .paginate_button a:not(.disabled):not(.next):not(.previous):hover,
  .paging_simple_numbers .paginate_button.current a{
    background: #00975E;
    color: #fefefe;
  }
  
  .paging_simple_numbers .paginate_button.page-item.active a{
      cursor: default;
      background: #00975E;
      color: #fefefe;
   }
  
  .paging_simple_numbers ul li a {
    transition: background-color 0.15s ease-in, color 0.15s ease-in;
    margin-left: 2px;
    margin-right: 2px;
  }  
  

}

