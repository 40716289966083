.modal {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
}
.modal-box {
  background-color: #f6f6fa;
  border: none;
  border-radius: 9;
  box-shadow: 0 1px 6px 0 rgba(42, 48, 67, 0.12);
}

.modal-head {
  display: inline;
  text-align: center;
  border-bottom: none;
}

.modal-head > button {
  position: absolute;
  top: 20px;
  right: 20px;
}
.modal-head > button:focus {
  box-shadow: none;
}

.modal-titles {
  font-weight: 900;
  font-size: 22px;
  color: #495057;
  text-align: center;
  margin: 1em auto auto auto;
}

.modal-input {
  display: block;
  width: 100%;
  padding: 0.2rem;
  font-size: 16px;
  font-weight: 400;
  line-height: 0.5;
  color: #495057;
  background-color: transparent;
  background-clip: padding-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /*border-radius: 0.25rem;*/
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid rgba(73, 80, 87, 0.25);
  border-radius: 0;
}

.modal-foot {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-input:focus {
  color: #212529;
  background-color: #fff;
  border-color: rgba(73, 80, 87, 0.2);
  outline: 0;
  box-shadow: none;
}

.drag-area {
  border: 2px dashed rgba(73, 80, 87, 0.5);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.drag-area.active {
  border: 2px solid rgb(73, 80, 87);
}
.drag-area .icona {
  font-size: 100px;
  color: rgba(73, 80, 87, 0.2);
}

.drag-area header {
  font-size: 30px;
  font-weight: 500;
  color: rgba(73, 80, 87, 0.5);
}
.drag-area span {
  font-size: 25px;
  font-weight: 500;
  color: rgb(73, 80, 87, 0.5);
  margin: 10px 0;
}

.drag-area img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.thumb-area {
  border: 2px solid rgba(73, 80, 87, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 240px;
}

.thumb-area .icona {
  font-size: 100px;
  color: rgba(73, 80, 87, 0.2);
}

.browse-area {
  border: 2px solid rgba(73, 80, 87, 0.3);
  display: flex;
  align-items: center;
}

.browse {
  border: none;
  background-color: transparent;
  font-size: 20px;
  font-weight: 500;
  color: rgba(73, 80, 87, 1);
}

textarea {
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(73, 80, 87, 0.2);
  font-weight: 400;
  font-size: 16px;
  color: #495057;
  padding-top: 10px;
  padding-left: 16px;
  margin: inherit;
}

.form-text-fw {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  color: #495057;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /*border-radius: 0.25rem;*/
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid rgba(73, 80, 87, 0.25);
  border-radius: 0;
}

.form-text-fw:focus {
  border-color: rgba(73, 80, 87, 0.2);
  outline: 0;
  box-shadow: none;
}
