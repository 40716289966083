.mejs-offscreen {
    border: 0;
    clip: rect(1px,1px,1px,1px);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal
}

.mejs-container {
    background: #000;
    font-family: Helvetica,Arial,serif;
    position: relative;
    text-align: left;
    text-indent: 0;
    vertical-align: top
}

.mejs-container,.mejs-container * {
    box-sizing: border-box
}

.mejs-container video::-webkit-media-controls,.mejs-container video::-webkit-media-controls-panel,.mejs-container video::-webkit-media-controls-panel-container,.mejs-container video::-webkit-media-controls-start-playback-button {
    -webkit-appearance: none;
    display: none!important
}

.mejs-fill-container,.mejs-fill-container .mejs-container {
    height: 100%;
    width: 100%
}

.mejs-fill-container {
    background: 0 0;
    margin: 0 auto;
    overflow: hidden;
    position: relative
}

.mejs-container:focus {
    outline: none
}

.mejs-iframe-overlay {
    height: 100%;
    position: absolute;
    width: 100%
}

.mejs-embed,.mejs-embed body {
    background: #000;
    height: 100%;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100%
}

.mejs-fullscreen {
    overflow: hidden!important
}

.mejs-container-fullscreen {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000
}

.mejs-container-fullscreen .mejs-mediaelement,.mejs-container-fullscreen video {
    height: 100%!important;
    width: 100%!important
}

.mejs-background,.mejs-mediaelement {
    left: 0;
    position: absolute;
    top: 0
}

.mejs-mediaelement {
    height: 100%;
    width: 100%;
    z-index: 0
}

.mejs-poster {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 1
}

:root .mejs-poster-img {
    display: none
}

.mejs-poster-img {
    border: 0;
    padding: 0
}

.mejs-overlay {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0
}

.mejs-layer {
    z-index: 1
}

.mejs-overlay-play {
    cursor: pointer
}

.mejs-overlay-button {
    background: url(/italy/wp-includes/js/mediaelement/mejs-controls.svg) no-repeat;
    background-position: 0 -39px;
    height: 80px;
    width: 80px
}

.mejs-overlay:hover>.mejs-overlay-button {
    background-position: -80px -39px
}

.mejs-overlay-loading {
    height: 80px;
    width: 80px
}

.mejs-overlay-loading-bg-img {
    -webkit-animation: a 1s linear infinite;
    animation: a 1s linear infinite;
    background: transparent url(/italy/wp-includes/js/mediaelement/mejs-controls.svg) -160px -40px no-repeat;
    display: block;
    height: 80px;
    width: 80px;
    z-index: 1
}

@-webkit-keyframes a {
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

@keyframes a {
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}

.mejs-controls {
    bottom: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 40px;
    left: 0;
    list-style-type: none;
    margin: 0;
    padding: 0 10px;
    position: absolute;
    width: 100%;
    z-index: 3
}

.mejs-controls:not([style*="display: none"]) {
    background: rgba(255,0,0,.7);
    background: -webkit-linear-gradient(transparent,rgba(0,0,0,.35));
    background: linear-gradient(transparent,rgba(0,0,0,.35))
}

.mejs-button,.mejs-time,.mejs-time-rail {
    font-size: 10px;
    height: 40px;
    line-height: 10px;
    margin: 0;
    width: 32px
}

.mejs-button>button {
    background: transparent url(/italy/wp-includes/js/mediaelement/mejs-controls.svg);
    border: 0;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 20px;
    line-height: 0;
    margin: 10px 6px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    text-decoration: none;
    width: 20px
}

.mejs-button>button:focus {
    outline: 1px dotted #999
}

.mejs-container-keyboard-inactive [role=slider],.mejs-container-keyboard-inactive [role=slider]:focus,.mejs-container-keyboard-inactive a,.mejs-container-keyboard-inactive a:focus,.mejs-container-keyboard-inactive button,.mejs-container-keyboard-inactive button:focus {
    outline: 0
}

.mejs-time {
    box-sizing: content-box;
    color: #fff;
    font-size: 11px;
    font-weight: 700;
    height: 24px;
    overflow: hidden;
    padding: 16px 6px 0;
    text-align: center;
    width: auto
}

.mejs-play>button {
    background-position: 0 0
}

.mejs-pause>button {
    background-position: -20px 0
}

.mejs-replay>button {
    background-position: -160px 0
}

.mejs-time-rail {
    direction: ltr;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    height: 40px;
    margin: 0 10px;
    padding-top: 10px;
    position: relative
}

.mejs-time-buffering,.mejs-time-current,.mejs-time-float,.mejs-time-float-corner,.mejs-time-float-current,.mejs-time-hovered,.mejs-time-loaded,.mejs-time-marker,.mejs-time-total {
    border-radius: 2px;
    cursor: pointer;
    display: block;
    height: 10px;
    position: absolute
}

.mejs-time-total {
    background: hsla(0,0%,100%,.3);
    margin: 5px 0 0;
    width: 100%
}

.mejs-time-buffering {
    -webkit-animation: b 2s linear infinite;
    animation: b 2s linear infinite;
    background: -webkit-linear-gradient(135deg,hsla(0,0%,100%,.4) 25%,transparent 0,transparent 50%,hsla(0,0%,100%,.4) 0,hsla(0,0%,100%,.4) 75%,transparent 0,transparent);
    background: linear-gradient(-45deg,hsla(0,0%,100%,.4) 25%,transparent 0,transparent 50%,hsla(0,0%,100%,.4) 0,hsla(0,0%,100%,.4) 75%,transparent 0,transparent);
    background-size: 15px 15px;
    width: 100%
}

@-webkit-keyframes b {
    0% {
        background-position: 0 0
    }

    to {
        background-position: 30px 0
    }
}

@keyframes b {
    0% {
        background-position: 0 0
    }

    to {
        background-position: 30px 0
    }
}

.mejs-time-loaded {
    background: hsla(0,0%,100%,.3)
}

.mejs-time-current,.mejs-time-handle-content {
    background: hsla(0,0%,100%,.9)
}

.mejs-time-hovered {
    background: hsla(0,0%,100%,.5);
    z-index: 10
}

.mejs-time-hovered.negative {
    background: rgba(0,0,0,.2)
}

.mejs-time-buffering,.mejs-time-current,.mejs-time-hovered,.mejs-time-loaded {
    left: 0;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transition: all .15s ease-in;
    transition: all .15s ease-in;
    width: 100%
}

.mejs-time-buffering {
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1)
}

.mejs-time-hovered {
    -webkit-transition: height .1s cubic-bezier(.44,0,1,1);
    transition: height .1s cubic-bezier(.44,0,1,1)
}

.mejs-time-hovered.no-hover {
    -webkit-transform: scaleX(0)!important;
    -ms-transform: scaleX(0)!important;
    transform: scaleX(0)!important
}

.mejs-time-handle,.mejs-time-handle-content {
    border: 4px solid transparent;
    cursor: pointer;
    left: 0;
    position: absolute;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    z-index: 11
}

.mejs-time-handle-content {
    border: 4px solid hsla(0,0%,100%,.9);
    border-radius: 50%;
    height: 10px;
    left: -7px;
    top: -4px;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    width: 10px
}

.mejs-time-rail .mejs-time-handle-content:active,.mejs-time-rail .mejs-time-handle-content:focus,.mejs-time-rail:hover .mejs-time-handle-content {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1)
}

.mejs-time-float {
    background: #eee;
    border: 1px solid #333;
    bottom: 100%;
    color: #111;
    display: none;
    height: 17px;
    margin-bottom: 9px;
    position: absolute;
    text-align: center;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 36px
}

.mejs-time-float-current {
    display: block;
    left: 0;
    margin: 2px;
    text-align: center;
    width: 30px
}

.mejs-time-float-corner {
    border: 5px solid #eee;
    border-color: #eee transparent transparent;
    border-radius: 0;
    display: block;
    height: 0;
    left: 50%;
    line-height: 0;
    position: absolute;
    top: 100%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 0
}

.mejs-long-video .mejs-time-float {
    margin-left: -23px;
    width: 64px
}

.mejs-long-video .mejs-time-float-current {
    width: 60px
}

.mejs-broadcast {
    color: #fff;
    height: 10px;
    position: absolute;
    top: 15px;
    width: 100%
}

.mejs-fullscreen-button>button {
    background-position: -80px 0
}

.mejs-unfullscreen>button {
    background-position: -100px 0
}

.mejs-mute>button {
    background-position: -60px 0
}

.mejs-unmute>button {
    background-position: -40px 0
}

.mejs-volume-button {
    position: relative
}

.mejs-volume-button>.mejs-volume-slider {
    -webkit-backface-visibility: hidden;
    background: rgba(50,50,50,.7);
    border-radius: 0;
    bottom: 100%;
    display: none;
    height: 115px;
    left: 50%;
    margin: 0;
    position: absolute;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 25px;
    z-index: 1
}

.mejs-volume-button:hover {
    border-radius: 0 0 4px 4px
}

.mejs-volume-total {
    background: hsla(0,0%,100%,.5);
    height: 100px;
    left: 50%;
    margin: 0;
    position: absolute;
    top: 8px;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 2px
}

.mejs-volume-current {
    left: 0;
    margin: 0;
    width: 100%
}

.mejs-volume-current,.mejs-volume-handle {
    background: hsla(0,0%,100%,.9);
    position: absolute
}

.mejs-volume-handle {
    border-radius: 1px;
    cursor: ns-resize;
    height: 6px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 16px
}

.mejs-horizontal-volume-slider {
    display: block;
    height: 36px;
    position: relative;
    vertical-align: middle;
    width: 56px
}

.mejs-horizontal-volume-total {
    background: rgba(50,50,50,.8);
    height: 8px;
    top: 16px;
    width: 50px
}

.mejs-horizontal-volume-current,.mejs-horizontal-volume-total {
    border-radius: 2px;
    font-size: 1px;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute
}

.mejs-horizontal-volume-current {
    background: hsla(0,0%,100%,.8);
    height: 100%;
    top: 0;
    width: 100%
}

.mejs-horizontal-volume-handle {
    display: none
}

.mejs-captions-button,.mejs-chapters-button {
    position: relative
}

.mejs-captions-button>button {
    background-position: -140px 0
}

.mejs-chapters-button>button {
    background-position: -180px 0
}

.mejs-captions-button>.mejs-captions-selector,.mejs-chapters-button>.mejs-chapters-selector {
    background: rgba(50,50,50,.7);
    border: 1px solid transparent;
    border-radius: 0;
    bottom: 100%;
    margin-right: -43px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: 50%;
    visibility: visible;
    width: 86px
}

.mejs-chapters-button>.mejs-chapters-selector {
    margin-right: -55px;
    width: 110px
}

.mejs-captions-selector-list,.mejs-chapters-selector-list {
    list-style-type: none!important;
    margin: 0;
    overflow: hidden;
    padding: 0
}

.mejs-captions-selector-list-item,.mejs-chapters-selector-list-item {
    color: #fff;
    cursor: pointer;
    display: block;
    list-style-type: none!important;
    margin: 0 0 6px;
    overflow: hidden;
    padding: 0
}

.mejs-captions-selector-list-item:hover,.mejs-chapters-selector-list-item:hover {
    background-color: #c8c8c8!important;
    background-color: hsla(0,0%,100%,.4)!important
}

.mejs-captions-selector-input,.mejs-chapters-selector-input {
    clear: both;
    float: left;
    left: -1000px;
    margin: 3px 3px 0 5px;
    position: absolute
}

.mejs-captions-selector-label,.mejs-chapters-selector-label {
    cursor: pointer;
    float: left;
    font-size: 10px;
    line-height: 15px;
    padding: 4px 10px 0;
    width: 100%
}

.mejs-captions-selected,.mejs-chapters-selected {
    color: #21f8f8
}

.mejs-captions-translations {
    font-size: 10px;
    margin: 0 0 5px
}

.mejs-captions-layer {
    bottom: 0;
    color: #fff;
    font-size: 16px;
    left: 0;
    line-height: 20px;
    position: absolute;
    text-align: center
}

.mejs-captions-layer a {
    color: #fff;
    text-decoration: underline
}

.mejs-captions-layer[lang=ar] {
    font-size: 20px;
    font-weight: 400
}

.mejs-captions-position {
    bottom: 15px;
    left: 0;
    position: absolute;
    width: 100%
}

.mejs-captions-position-hover {
    bottom: 35px
}

.mejs-captions-text,.mejs-captions-text * {
    background: hsla(0,0%,8%,.5);
    box-shadow: 5px 0 0 hsla(0,0%,8%,.5),-5px 0 0 hsla(0,0%,8%,.5);
    padding: 0;
    white-space: pre-wrap
}

.mejs-container.mejs-hide-cues video::-webkit-media-text-track-container {
    display: none
}

.mejs-overlay-error {
    position: relative
}

.mejs-overlay-error>img {
    left: 0;
    max-width: 100%;
    position: absolute;
    top: 0;
    z-index: -1
}

.mejs-cannotplay,.mejs-cannotplay a {
    color: #fff;
    font-size: .8em
}

.mejs-cannotplay {
    position: relative
}

.mejs-cannotplay a,.mejs-cannotplay p {
    display: inline-block;
    padding: 0 15px;
    width: 100%
}

.mejs-container {
    clear: both;
    max-width: 100%
}

.mejs-container * {
    font-family: Helvetica,Arial
}

.mejs-container,.mejs-container .mejs-controls,.mejs-embed,.mejs-embed body {
    background: #222
}

.mejs-time {
    font-weight: 400;
    word-wrap: normal
}

.mejs-controls a.mejs-horizontal-volume-slider {
    display: table
}

.mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current,.mejs-controls .mejs-time-rail .mejs-time-loaded {
    background: #fff
}

.mejs-controls .mejs-time-rail .mejs-time-current {
    background: #0073aa
}

.mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-total,.mejs-controls .mejs-time-rail .mejs-time-total {
    background: rgba(255,255,255,.33)
}

.mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current,.mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-total,.mejs-controls .mejs-time-rail span {
    border-radius: 0
}

.mejs-overlay-loading {
    background: 0 0
}

.mejs-controls button:hover {
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none
}

.me-cannotplay {
    width: auto!important
}

.media-embed-details .wp-audio-shortcode {
    display: inline-block;
    max-width: 400px
}

.audio-details .embed-media-settings {
    overflow: visible
}

.media-embed-details .embed-media-settings .setting span:not(.button-group) {
    max-width: 400px;
    width: auto
}

.media-embed-details .embed-media-settings .checkbox-setting span {
    display: inline-block
}

.media-embed-details .embed-media-settings {
    padding-top: 0;
    top: 28px
}

.media-embed-details .instructions {
    padding: 16px 0;
    max-width: 600px
}

.media-embed-details .setting .remove-setting,.media-embed-details .setting p {
    color: #a00;
    font-size: 10px;
    text-transform: uppercase
}

.media-embed-details .setting .remove-setting {
    padding: 5px 0
}

.media-embed-details .setting a:hover {
    color: #dc3232
}

.media-embed-details .embed-media-settings .checkbox-setting {
    float: none;
    margin: 0 0 10px
}

.wp-video {
    max-width: 100%;
    height: auto
}

.wp_attachment_holder .wp-audio-shortcode,.wp_attachment_holder .wp-video {
    margin-top: 18px
}

.wp-video-shortcode video,video.wp-video-shortcode {
    max-width: 100%;
    display: inline-block
}

.video-details .wp-video-holder {
    width: 100%;
    max-width: 640px
}

.wp-playlist {
    border: 1px solid #ccc;
    padding: 10px;
    margin: 12px 0 18px;
    font-size: 14px;
    line-height: 1.5
}

.wp-admin .wp-playlist {
    margin: 0 0 18px
}

.wp-playlist video {
    display: inline-block;
    max-width: 100%
}

.wp-playlist audio {
    display: none;
    max-width: 100%;
    width: 400px
}

.wp-playlist .mejs-container {
    margin: 0;
    max-width: 100%
}

.wp-playlist .mejs-controls .mejs-button button {
    outline: 0
}

.wp-playlist-light {
    background: #fff;
    color: #000
}

.wp-playlist-dark {
    color: #fff;
    background: #000
}

.wp-playlist-caption {
    display: block;
    max-width: 88%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    line-height: 1.5
}

.wp-playlist-item .wp-playlist-caption {
    text-decoration: none;
    color: #000;
    max-width: -webkit-calc(100% - 40px);
    max-width: calc(100% - 40px)
}

.wp-playlist-item-meta {
    display: block;
    font-size: 14px;
    line-height: 1.5
}

.wp-playlist-item-title {
    font-size: 14px;
    line-height: 1.5
}

.wp-playlist-item-album {
    font-style: italic;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.wp-playlist-item-artist {
    font-size: 12px;
    text-transform: uppercase
}

.wp-playlist-item-length {
    position: absolute;
    right: 3px;
    top: 0;
    font-size: 14px;
    line-height: 1.5
}

.rtl .wp-playlist-item-length {
    left: 3px;
    right: auto
}

.wp-playlist-tracks {
    margin-top: 10px
}

.wp-playlist-item {
    position: relative;
    cursor: pointer;
    padding: 0 3px;
    border-bottom: 1px solid #ccc
}

.wp-playlist-item:last-child {
    border-bottom: 0
}

.wp-playlist-light .wp-playlist-caption {
    color: #333
}

.wp-playlist-dark .wp-playlist-caption {
    color: #ddd
}

.wp-playlist-playing {
    font-weight: 700;
    background: #f7f7f7
}

.wp-playlist-light .wp-playlist-playing {
    background: #fff;
    color: #000
}

.wp-playlist-dark .wp-playlist-playing {
    background: #000;
    color: #fff
}

.wp-playlist-current-item {
    overflow: hidden;
    margin-bottom: 10px;
    height: 60px
}

.wp-playlist .wp-playlist-current-item img {
    float: left;
    max-width: 60px;
    height: auto;
    margin-right: 10px;
    padding: 0;
    border: 0
}

.rtl .wp-playlist .wp-playlist-current-item img {
    float: right;
    margin-left: 10px;
    margin-right: 0
}

.wp-playlist-current-item .wp-playlist-item-artist,.wp-playlist-current-item .wp-playlist-item-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.wp-audio-playlist .me-cannotplay span {
    padding: 5px 15px
}
